import { useTheme } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

import { useResponsive } from 'src/hooks/use-responsive'

import { bgBlur } from 'src/theme/css'

import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'

import { NAV } from '../config-layout'

// ----------------------------------------------------------------------

export default function NavToggleButton({ sx, ...other }: IconButtonProps) {
  const theme = useTheme()

  const settings = useSettingsContext()

  const mdUp = useResponsive('up', 'md')

  if (!mdUp) {
    return null
  }

  return (
    <IconButton
      size="small"
      onClick={() =>
        settings.onUpdate(
          'themeLayout',
          settings.themeLayout === 'vertical' ? 'mini' : 'vertical'
        )
      }
      sx={{
        p: 0.5,
        position: 'absolute',
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        transform: 'translateY(0px) translateX(-50%)',
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          settings.themeLayout === 'vertical'
            ? 'eva:arrow-ios-forward-fill'
            : 'eva:arrow-ios-back-fill'
        }
      />
    </IconButton>
  )
}
