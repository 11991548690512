import { useMemo } from 'react'
import useSWR, { mutate } from 'swr'

import { fetcher, endpoints } from 'src/utils/axios'

import { ICalendarEvent } from 'src/types/calendar'

// ----------------------------------------------------------------------

const URL = endpoints.calendar.getAll()

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

export function useGetCalendars() {
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options)

  const memoizedValue = useMemo(() => {
    const calendars = data?.calendars.map((calendar: ICalendarEvent) => ({
      ...calendar,
    }))

    return {
      calendars: (calendars as ICalendarEvent[]) || [],
      calendarsLoading: isLoading,
      calendarsError: error,
      calendarsValidating: isValidating,
      calendarsEmpty: !isLoading && !data?.calendars.length,
    }
  }, [data?.calendars, error, isLoading, isValidating])

  return memoizedValue
}

// ----------------------------------------------------------------------

export async function createCalendar(eventData: ICalendarEvent) {
  /** Work on server */
  // const data = { eventData };
  // await axios.post(URL, data);
  /** Work in local */
  mutate(
    URL,
    (currentData: any) => {
      const events: ICalendarEvent[] = [...currentData.events, eventData]

      return {
        ...currentData,
        events,
      }
    },
    false,
  )
}

// ----------------------------------------------------------------------

export async function updateCalendar(eventData: Partial<ICalendarEvent>) {
  /** Work on server */
  // const data = { eventData };
  // await axios.put(endpoints.calendar, data);
  /** Work in local */
  mutate(
    URL,
    (currentData: any) => {
      const events: ICalendarEvent[] = currentData.events.map(
        (event: ICalendarEvent) =>
          event.id === eventData.id ? { ...event, ...eventData } : event,
      )

      return {
        ...currentData,
        events,
      }
    },
    false,
  )
}

// ----------------------------------------------------------------------

export async function deleteCalendar(eventId: string) {
  /** Work on server */
  // const data = { eventId };
  // await axios.patch(endpoints.calendar, data);
  /** Work in local */
  mutate(
    URL,
    (currentData: any) => {
      const events: ICalendarEvent[] = currentData.events.filter(
        (event: ICalendarEvent) => event.id !== eventId,
      )

      return {
        ...currentData,
        events,
      }
    },
    false,
  )
}
