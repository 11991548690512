import { memo, useState, useCallback, Fragment } from 'react'
import _ from 'lodash'

import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import { TacTab, TacTabs } from '@goard/tac-ui-kit'

import { NavProps, NavGroupProps } from '../types'

// ----------------------------------------------------------------------

function NavSectionVertical({ data, slotProps, ...other }: NavProps) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const renderTab = data.map((group, index) => {
    if (group.subheader !== 'footer') {
      const label = _.capitalize(group.subheader)
      return (
        <TacTab
          sx={{
            maxWidth: '150px',
          }}
          key={group.subheader || index}
          label={
            <Box
              component="span"
              sx={{
                width: '100%',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                whiteSpace: 'break-spaces',
              }}
            >
              {label}
            </Box>
          }
          {...a11yProps(index)}
        />
      )
    }
  })

  const renderTabPanel = data.map((group, index) => {
    if (group.subheader !== 'footer') {
      return (
        <TabPanel key={group.subheader || index} value={value} index={index}>
          {group.items.map((item, index) => {
            return <Fragment key={index}>{item.component()}</Fragment>
          })}
        </TabPanel>
      )
    }
  })

  return (
    <Stack component="nav" id="nav-section-vertical" {...other} sx={{ px: 4 }}>
      <TacTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {renderTab}
      </TacTabs>
      {renderTabPanel}
    </Stack>
  )
}

export default memo(NavSectionVertical)

// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Box
      sx={{ marginTop: '21px' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: Number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function Group({ subheader, items, slotProps }: NavGroupProps) {
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const renderContent = items.map((list, index) => (
    <>
      <TabPanel value="1" index={index}></TabPanel>
    </>
  ))

  return (
    <Stack sx={{ px: 2 }}>
      {subheader ? (
        <>
          <TacTab sx={{ minHeight: 0 }} label={subheader} {...a11yProps(0)} />

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </Stack>
  )
}
