import { IconButton, Tooltip, Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import SettingsIcon from 'src/components/SvgIcons/SettingsIcon'
import GuideIcon from 'src/components/SvgIcons/GuideIcon'
import ShareIcon from 'src/components/SvgIcons/ShareIcon'
import NewsIcon from 'src/components/SvgIcons/NewsIcon'
import SettingsButton from 'src/layouts/common/settings-button'
import LanguagePopover from 'src/layouts/common/language-popover'

type Props = {
  items: Array
  handleClickOpen?: VoidFunction
  direction?: 'row' | 'column'
}

function StickyItem({ title, handleClickOpen }) {
  return (
    <Stack flexDirection="row" justifyContent="center">
      <Tooltip title={title} placement="left">
        <IconButton onClick={handleClickOpen}>
          <SettingsIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

const StickyMenu = ({ items, handleClickOpen, direction }: Props) => {
  console.log('data sticky', items)
  return (
    <Stack
      sx={{
        // backgroundColor: '#FFFFFF',
        flexDirection: direction,
        justifyContent: 'space-between',
        px: '0.8rem',
        py: '0.5rem',
      }}
    >
      <Stack flexDirection="row" justifyContent="center">
        <Tooltip title="menu" placement="left">
          <IconButton onClick={handleClickOpen}>
            <SettingsIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack flexDirection="row" justifyContent="center">
        <Tooltip title="guide" placement="left">
          <IconButton
            href="https://wiki.teamlead.one/doc/cal_cloud"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GuideIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack flexDirection="row" justifyContent="center">
        <Tooltip title="share" placement="left">
          <IconButton onClick={handleClickOpen}>
            <ShareIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack flexDirection="row" justifyContent="center">
        <Tooltip title="news" placement="left">
          <IconButton
            href="https://www.teamlead.one/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewsIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack flexDirection="row" justifyContent="center">
        <Tooltip title="settings" placement="left">
          <div>
            <SettingsButton />
          </div>
        </Tooltip>
      </Stack>
      <Stack flexDirection="row" justifyContent="center">
        <Tooltip title="language" placement="left">
          <div>
            <LanguagePopover />
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default StickyMenu
