import React from 'react'
import { Draggable } from '@hello-pangea/dnd'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

const IssueItem = ({ item, index }) => {
  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided) => (
        <Box
          className="external-event"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-id={item.id}
          data-title={item.name}
          sx={{
            ...provided.draggableProps.style,
            border: '1px solid rgb(0, 184, 216)',
            borderRadius: '3px',
            backgroundColor: 'rgb(0, 184, 216)',
            marginBottom: '3px',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Avatar
              sx={{
                height: '38px',
                width: '38px',
                marginRight: '10px',
              }}
            >
              df
            </Avatar>
            <Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'rgb(23, 43, 77)',
                }}
              >
                {item.key}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'rgb(23, 43, 77)',
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  )
}

export { IssueItem }
