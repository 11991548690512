import { SvgIcon } from '@mui/material'

export default function GuideIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19C11.7348 19 11.4805 18.8946 11.2929 18.7071C11.1054 18.5195 11 18.2652 11 18C11 17.7347 11.1054 17.4804 11.2929 17.2929C11.4805 17.1053 11.7348 17 12 17C12.2653 17 12.5196 17.1053 12.7071 17.2929C12.8947 17.4804 13 17.7347 13 18C13 18.2652 12.8947 18.5195 12.7071 18.7071C12.5196 18.8946 12.2653 19 12 19ZM10 9.99996C10 10.2652 9.89468 10.5195 9.70715 10.7071C9.51961 10.8946 9.26526 11 9.00004 11C8.73482 11 8.48047 10.8946 8.29293 10.7071C8.1054 10.5195 8.00004 10.2652 8.00004 9.99996C8.00004 9.73474 8.1054 9.48039 8.29293 9.29285C8.48047 9.10532 8.73482 8.99996 9.00004 8.99996C9.26526 8.99996 9.51961 9.10532 9.70715 9.29285C9.89468 9.48039 10 9.73474 10 9.99996Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.89 9.04796C15.7187 8.33269 15.352 7.67907 14.831 7.15996C14.3099 6.64084 13.6549 6.27664 12.939 6.10796C12.3434 5.96496 11.7228 5.96051 11.1251 6.09497C10.5275 6.22942 9.96861 6.49919 9.49155 6.88352C9.0145 7.26784 8.63197 7.75649 8.3734 8.31185C8.11482 8.86721 7.98709 9.47449 8.00004 10.087C8.00281 10.0899 8.00582 10.0925 8.00904 10.095L9.88704 10.117L9.99904 10.001C9.9993 9.47034 10.2102 8.96153 10.5854 8.58633C10.9606 8.21113 11.4694 8.00022 12 7.99996C13.103 7.99996 14 8.89696 14 9.99996C14 11.102 13.103 12 12 12H12.008C11.7454 12.0005 11.4935 12.1043 11.3068 12.2889C11.12 12.4736 11.0135 12.7243 11.01 12.987V15.001C11.01 15.2662 11.1154 15.5205 11.3029 15.7081C11.4905 15.8956 11.7448 16.001 12.01 16.001C12.2753 16.001 12.5296 15.8956 12.7171 15.7081C12.9047 15.5205 13.01 15.2662 13.01 15.001V14.219C13.01 14.002 13.155 13.82 13.359 13.747C14.2864 13.4141 15.0579 12.7501 15.525 11.8825C15.9921 11.015 16.1217 10.0054 15.889 9.04796"
      />
      <path d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z" />
    </SvgIcon>
  )
}
