import { memo } from 'react'

import Stack from '@mui/material/Stack'
import Scrollbar from 'src/components/scrollbar'
import NavList from './nav-list'
import { NavProps, NavGroupProps } from '../types'
import StickyMenu from '../footer-menu'

const FOOTER = 'footer'.toLowerCase()
// ----------------------------------------------------------------------

function NavSectionMini({ data, slotProps, ...other }: NavProps) {
  return (
    <Stack
      component="nav"
      id="nav-section-mini"
      spacing={`${slotProps?.gap || 4}px`}
      sx={{
        height: '100%',
      }}
      {...other}
    >
      <Scrollbar>
        {data.map((group, index) => {
          if (group.subheader !== FOOTER)
            return (
              <Group
                key={group.subheader || index}
                items={group.items}
                slotProps={slotProps}
              />
            )
        })}
      </Scrollbar>
      {data.map((group, index) => {
        if (group.subheader === FOOTER)
          return (
            <StickyMenu key={group.subheader || index} items={group.items} />
          )
      })}
    </Stack>
  )
}

export default memo(NavSectionMini)

// ----------------------------------------------------------------------

function Group({ items, slotProps }: NavGroupProps) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title} data={list} depth={1} slotProps={slotProps} />
      ))}
    </>
  )
}
