import Stack from '@mui/material/Stack'

import { useMockedUser } from 'src/hooks/use-mocked-user'

import { hideScroll } from 'src/theme/css'

import Logo from 'src/components/logo'
import { NavSectionMini } from 'src/components/nav-section'

import { NAV } from '../config-layout'
import NavToggleButton from '../common/nav-toggle-button'
import StickyMenu from 'src/components/nav-section/footer-menu'
// ----------------------------------------------------------------------


const FOOTER = 'footer'.toLowerCase()

type NavItemBaseProps = {
  title: string
  path: string
  icon?: React.ReactElement
  info?: React.ReactElement
  caption?: string
  disabled?: boolean
  roles?: string[]
  children?: any
}

type Props = {
  data: {
    subheader: string
    items: NavItemBaseProps[]
  }[]
  openNav?: boolean
  onCloseNav?: VoidFunction
}

export default function NavMini({ data }: Props) {
  const { user } = useMockedUser()

  return (
    <>
      <NavToggleButton />

      <Stack
        sx={{
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          backgroundColor: (theme) => theme.palette.background.neutral,
          ...hideScroll.x,
        }}
      >
        <Logo disabledLink={true} sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini
          data={data}
          slotProps={{
            currentRole: user?.role,
          }}
        />
        {/* {data.map((group, index) => {
          if (group.subheader === FOOTER)
            return (
              <StickyMenu key={group.subheader || index} items={group.items} />
            )
        })} */}
      </Stack>
    </>
  )
}
