import { RED, YELLOW, GREEN, BLUE, MAGENTA, NEUTRAL } from '../colors'

const defaultLight = {
  ligth: NEUTRAL['A100'],
  main: NEUTRAL['A200'],
  dark: NEUTRAL['A300'], // hover
  darker: NEUTRAL['A400'],
  contrastText: NEUTRAL[1000],
}

const primaryLight = {
  lighter: BLUE[500],
  light: BLUE[600],
  main: BLUE[700],
  dark: BLUE[800],
  darker: BLUE[900],
  contrastText: '#FFFFFF',
}

const secondaryLight = {
  lighter: MAGENTA[500],
  light: MAGENTA[600],
  main: MAGENTA[700],
  dark: MAGENTA[800],
  darker: MAGENTA[900],
  contrastText: '#FFFFFF',
}

const infoLight = {
  lighter: BLUE[500],
  light: BLUE[600],
  main: BLUE[700],
  dark: BLUE[800],
  darker: BLUE[900],
  contrastText: '#FFFFFF',
}

const successLight = {
  lighter: GREEN[500],
  light: GREEN[600],
  main: GREEN[700],
  dark: GREEN[800],
  darker: GREEN[900],
  contrastText: '#FFFFFF',
}

const warningLight = {
  lighter: YELLOW[100],
  light: YELLOW[200],
  main: YELLOW[300],
  dark: YELLOW[400],
  darker: YELLOW[500],
  contrastText: NEUTRAL[1000],
}

const errorLight = {
  lighter: RED[500],
  light: RED[600],
  main: RED[700],
  dark: RED[800],
  darker: RED[900],
  contrastText: '#FFFFFF',
}

const actionLight = {
  hover: NEUTRAL[100],
  active: NEUTRAL['A400'],
  selected: BLUE[400],
  disabled: NEUTRAL['A400'],
  disabledBackground: NEUTRAL['A100'],
  // focus: alpha(grey[500], 0.24),
  // hoverOpacity: 0.08,
  disabledOpacity: 0.48,
}

export const lightMode = {
  default: defaultLight,
  primary: primaryLight,
  secondary: secondaryLight,
  info: infoLight,
  success: successLight,
  warning: warningLight,
  error: errorLight,
  action: actionLight,
}
