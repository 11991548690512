import axios, { AxiosRequestConfig } from 'axios'

import { HOST_API } from 'src/config-global'

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API })

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
)

export default axiosInstance

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args]

  const res = await axiosInstance.get(url, { ...config })

  return res.data
}

export const fetcherPost = async (
  args: string | [string, AxiosRequestConfig],
) => {
  const [url, config] = Array.isArray(args) ? args : [args]

  const res = await axiosInstance.post(url, { ...config })

  return res.data
}

// ----------------------------------------------------------------------

export const endpoints = {
  calendar: {
    fake: '/api/calendar',
    path: '/v1/calendar',
    getAll: function () {
      return this.path
    },
    getOne: function ({ calendarId }) {
      return this.path + calendarId
    },
    createNew: function ({ calendarId }) {
      return this.path + calendarId
    },
    updateOne: function ({ calendarId }) {
      return this.path + calendarId
    },
    deleteOne: function ({ calendarId }) {
      return this.path + calendarId
    },
  },
  issue: {
    path: '/v1/issue'
  },
  event: {
    path: '/v1/event',
    getAll: function () {
      return this.path
    },
    getOne: function ({ eventId }) {
      return this.path + '/' + eventId
    },
    createNew: function ({ eventId }) {
      return this.path + '/' + eventId
    },
    updateOne: function ({ eventId }) {
      return this.path + '/' + eventId
    },
    deleteOne: function ({ eventId }) {
      return this.path + '/' + eventId
    },
  },
  eventJira: {
    path: '/v1/event/jira',
    getAll: function () {
      return this.path
    },
    getOne: function ({ eventId }) {
      return this.path + '/' + eventId
    },
    createNew: function ({ eventId }) {
      return this.path + '/' + eventId
    },
    updateOne: function ({ eventId }) {
      return this.path + '/' + eventId
    },
    deleteOne: function ({ eventId }) {
      return this.path + '/' + eventId
    },
  },
}
