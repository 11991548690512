import { Suspense, lazy } from 'react'
import { Outlet } from 'react-router-dom'
import CalendarLayout from 'src/layouts/calendar'
import { SplashScreen, LoadingScreen } from 'src/components/loading-screen'

export const CalendarPage = lazy(() => import('src/pages/calendar'))
export const SettingsPage = lazy(() => import('src/pages/settings'))

export const mainRoutes = [
  {
    path: '/',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <CalendarLayout>
          <Outlet />
        </CalendarLayout>
      </Suspense>
    ),
    children: [
      { element: <CalendarPage />, index: true },
      { path: 'settings', element: <SettingsPage /> },
    ],
  },
]
