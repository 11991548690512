import { forwardRef, useState } from 'react'
import _ from 'lodash'

import Card from '@mui/material/Card'
import { CardHeader, CardContent, Box, Fade } from '@mui/material'

const arrItems = [
  { id: 1, name: 'Event' },
  { id: 2, name: 'Issue' },
  { id: 3, name: 'Exiting issue' },
]

const CreateCard = forwardRef<HTMLDivElement, any>(
  ({ title, handleClickOpen, ...others }, ref) => {
    const [] = useState(false)
    const headerTitle = _.capitalize(title)
    return (
      <>
        <Fade in={true}>
          <Card {...others} ref={ref}>
            <CardHeader
              title={headerTitle}
              sx={{ padding: '14px 14px 16px 14px' }}
            />
            <CardContent
              sx={{
                padding: '0 14px 14px 14px',
                '&:last-child': {
                  paddingBottom: '14px',
                },
              }}
            >
              {' '}
              {arrItems.map((item) => (
                <Box
                  key={item.id}
                  onClick={() => {
                    handleClickOpen()
                  }}
                  sx={{
                    lineHeight: 1,
                    marginBottom: '6px',
                    cursor: 'pointer',

                    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    '&::before': {
                      marginLeft: '-3px',
                      display: 'inline-block',
                      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                      content: '"\\202F"',
                      borderLeft: (theme) =>
                        `2px solid ${theme.palette.primary.main}`,
                      transform: 'scaleY(0)',
                      // fontSize: 0,
                    },
                    '&:hover::before': {
                      // fontSize: '14px',
                      transform: 'scaleY(1)',
                      marginRight: '6px',
                    },
                    '&:hover': {
                      marginLeft: '3px',
                      // paddingLeft: '6px',
                    },
                    '&:active': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  {item.name}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Fade>
      </>
    )
  }
)

export default CreateCard
