import { useEffect } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'

import { usePathname } from 'src/routes/hooks'

import { useResponsive } from 'src/hooks/use-responsive'
import { useMockedUser } from 'src/hooks/use-mocked-user'

import Logo from 'src/components/logo'
import Scrollbar from 'src/components/scrollbar'
import { NavSectionVertical } from 'src/components/nav-section'

import { NAV } from '../config-layout'
import NavToggleButton from '../common/nav-toggle-button'
import StickyMenu from 'src/components/nav-section/footer-menu'

const FOOTER = 'footer'.toLowerCase()

// ----------------------------------------------------------------------

type NavItemBaseProps = {
  title: string
  path: string
  icon?: React.ReactElement
  info?: React.ReactElement
  caption?: string
  disabled?: boolean
  roles?: string[]
  children?: any
}

type Props = {
  data: {
    subheader: string
    items: NavItemBaseProps[]
  }[]
  openNav: boolean
  onCloseNav: VoidFunction
}

export default function NavVertical({ data, openNav, onCloseNav }: Props) {
  const { user } = useMockedUser()

  const pathname = usePathname()

  const mdUp = useResponsive('up', 'md')

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
          backgroundColor: 'default.main',
        }}
      >
        <Logo disabledLink={true} sx={{ mt: 3, ml: 4, mb: 1 }} />

        <NavSectionVertical
          data={data}
          slotProps={{
            currentRole: user?.role,
          }}
        />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
      {data.map((group, index) => {
        if (group.subheader === FOOTER)
          return (
            <StickyMenu
              direction="row"
              key={group.subheader || index}
              items={group}
            />
          )
      })}
    </>
  )

  return (
    <>
      <NavToggleButton />

      {mdUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            backgroundColor: (theme) => theme.palette.background.neutral,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          anchor="right"
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  )
}
