import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { useLocales } from './use-locales'
import { useSettingsContext } from 'src/components/settings'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function LocalizationProvider({ children }: Props) {
  /**
   * Do not remove this function!!! This is function update locale (language) mui-x-picker
   * Не удаляй эту функцию. Необходима для обновления языка календаря mui x без перезагрузки страницы.
   */
  // useSettingsContext()
  const { currentLang } = useLocales()

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={currentLang.adapterLocale}
    >
      {children}
    </MuiLocalizationProvider>
  )
}
