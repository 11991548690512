import { GREY, NEUTRAL, DARKNEUTRAL } from './colors'
import { lightMode, darkMode } from './paletteMode'
// ----------------------------------------------------------------------

declare module '@mui/material/styles/createPalette' {
  interface SimplePaletteColorOptions {
    lighter: string
    darker: string
  }
  interface PaletteColor {
    lighter: string
    darker: string
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary']
    darkNeutral: Palette['grey']
  }

  interface PaletteOptions {
    default?: PaletteOptions['primary']
    darkNeutral: PaletteOptions['grey']
  }
}

// SETUP COLORS

export const common = {
  black: '#000000',
  white: '#FFFFFF',
}

const base = {
  common,
}

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    ...lightMode,
    mode: 'light',
    grey: NEUTRAL,
    divider: NEUTRAL['A300'],
    text: {
      primary: NEUTRAL[1000],
      secondary: NEUTRAL[800],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
  } as const

  const dark = {
    ...base,
    ...darkMode,
    mode: 'dark',
    grey: DARKNEUTRAL,
    text: {
      primary: DARKNEUTRAL[900],
      secondary: DARKNEUTRAL[800],
      disabled: DARKNEUTRAL['A400'],
    },
    background: {
      paper: DARKNEUTRAL[100],
      default: DARKNEUTRAL[100],
    },
  } as const

  return mode === 'light' ? light : dark
}
