import { useMemo } from 'react'

import { paths } from 'src/routes/paths'

import { useTranslate } from 'src/locales'

import SvgColor from 'src/components/svg-color'
import { CalendarCard } from 'src/components/calendar-card'
import { CreateCard } from 'src/components/create-card'
import { UnplannedCard } from 'src/components/unplanned-card'

// ----------------------------------------------------------------------

const icon = (name: string) => {
  return (
    <SvgColor src={name} sx={{ width: 1, height: 1 }} />
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
  )
}

const ICONS = {
  issue: icon(
    new URL(`/src/assets/icons/navbar/ic_issues.svg`, import.meta.url).href
  ),
  addItem: icon(
    new URL(`/src/assets/icons/navbar/ic_add_item.svg`, import.meta.url).href
  ),
  calendar: icon(
    new URL(`/src/assets/icons/navbar/ic_calendar.svg`, import.meta.url).href
  ),
  settings: icon(
    new URL(`/src/assets/icons/navbar/ic_settings.svg`, import.meta.url).href
  ),
  guide: icon(
    new URL(`/src/assets/icons/navbar/ic_guide.svg`, import.meta.url).href
  ),
  share: icon(
    new URL(`/src/assets/icons/navbar/ic_share.svg`, import.meta.url).href
  ),
  news: icon(
    new URL(`/src/assets/icons/navbar/ic_news.svg`, import.meta.url).href
  ),
}

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate()
  const data = useMemo(
    () => [
      {
        subheader: t('main'),
        items: [
          {
            title: t('create'),
            path: paths.product.root,
            icon: ICONS.addItem,
            component: function (title: string) {
              return <CreateCard title={this.title} />
            },
          },
          {
            title: t('calendars'),
            path: paths.product.root,
            icon: ICONS.calendar,
            component: function (title: string) {
              return <CalendarCard title={this.title} />
            },
          },
        ],
      },
      {
        subheader: t('unplanned issues'),
        items: [
          {
            title: t('issues'),
            path: paths.product.root,
            icon: ICONS.issue,
            component: function (props: any) {
              return <UnplannedCard {...props} />
            },
          },
        ],
      },
      {
        subheader: t('footer'),
        items: [
          {
            title: t('settings'),
            path: paths.settings.root,
            icon: ICONS.settings,
          },
          {
            title: t('guide'),
            path: paths.changelog,
            icon: ICONS.guide,
          },
          {
            title: t('share'),
            path: paths.product.root,
            icon: ICONS.share,
          },
          {
            title: t('news'),
            path: paths.minimalUI,
            icon: ICONS.news,
          },
        ],
      },
    ],
    [t]
  )

  return data
}
