import React, { forwardRef, useState, useCallback } from 'react'
import _ from 'lodash'
import {
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  Typography,
  Stack,
} from '@mui/material'
import { TacCheckbox, TacRadio, TacRadioGroup } from '@goard/tac-ui-kit'

import ListSubheader from '@mui/material/ListSubheader'
import Collapse from '@mui/material/Collapse'

import SvgColor from 'src/components/svg-color'
import { useTranslate } from 'src/locales'
import { useGetCalendars } from 'src/api/calendar'

const arrGoogleCalendar = [
  {
    id: 1,
    name: 'Google calendar1 Google calendar1 Google calendar1 Google calendar1 Google calendar1',
  },
  { id: 2, name: 'Google calendar2' },
  { id: 3, name: 'Google calendar3' },
]

const PlusIcon = () => {
  const src = new URL(`/src/assets/icons/navbar/ic_issues.svg`, import.meta.url)
    .href
  return <SvgColor src={src} sx={{ width: 22, height: 22, flexShrink: 0 }} />
}

const InternaCalendar = React.memo(({ items }) => {
  return (
    <>
      <FormControl>
        <TacRadioGroup name="defaultCalendar">
          {items.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.name}
              control={<TacRadio sx={{ padding: '2px' }} color="primary" />}
              label={
                <Typography
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {item.name}
                </Typography>
              }
              sx={{ marginBottom: '2px', marginLeft: '-6px', marginRight: 0 }}
            />
          ))}
        </TacRadioGroup>
      </FormControl>
    </>
  )
})

function ExternalCalendar({ items }) {
  return (
    <>
      {items.map((item: Object) => (
        <Box mt="2px" key={item.id}>
          <FormControlLabel
            sx={{ marginLeft: '-5px', marginRight: 0 }}
            key={item.id}
            control={<TacCheckbox color="primary" />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            }
          />
        </Box>
      ))}
    </>
  )
}

function Group({ subheader, type, items, slotProps }) {
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  return (
    <Stack>
      {items.length !== 0 && (
        <>
          <ListSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            sx={{
              fontSize: 11,
              cursor: 'pointer',
              typography: 'overline',
              display: 'inline-flex',
              color: 'text.disabled',
              mb: `${slotProps?.gap || 4}px`,
              transition: (theme) =>
                theme.transitions.create(['color'], {
                  duration: theme.transitions.duration.shortest,
                }),
              '&:hover': {
                color: 'text.primary',
              },
              ...slotProps?.subheader,
            }}
          >
            {subheader}
          </ListSubheader>

          <Collapse in={open}>
            {type === 'internal' ? (
              <InternaCalendar items={items} />
            ) : (
              <ExternalCalendar items={items} />
            )}
          </Collapse>
        </>
      )}
    </Stack>
  )
}

const CalendarCard = forwardRef<HTMLDivElement, any>(
  ({ title, ...others }, ref) => {
    const { t } = useTranslate()
    // const { calendars } = useGetCalendars()

    const calendarsArr = [
      {
        subheader: t('internal calendars'),
        type: 'internal',
        calendars: arrGoogleCalendar,
      },
      {
        subheader: t('external calendars'),
        type: 'external',
        calendars: arrGoogleCalendar,
      },
    ]

    const headerTitle = _.capitalize(title)
    return (
      <>
        <Fade in={true}>
          <Card {...others} ref={ref}>
            <CardHeader title={headerTitle} />
            <CardContent
              sx={{
                paddingTop: 0,
                overflowY: 'auto',
                listStyle: 'none',
                maxHeight: `calc(100vh - 410px)`,
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: '3px',
                  backgroundColor: '#EBECF0',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#6B778C',
                  borderRadius: '3px',
                  height: '16px',
                },
              }}
            >
              {calendarsArr.map((group, index) => (
                <Group
                  key={group.subheader || index}
                  subheader={group.subheader}
                  type={group.type}
                  items={group.calendars}
                />
              ))}
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#42526E',
                  userSelect: 'none',
                  paddingLeft: '12px',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#8993A4',
                  },
                  '&:active': {
                    color: '#0052CC',
                  },
                }}
              >
                <PlusIcon sx={{ fontSize: '12px', marginRight: '10px' }} />
                Add calendar
              </Box>
            </CardActions>
          </Card>
        </Fade>
      </>
    )
  },
)

export default CalendarCard
