import React, { useContext, createContext } from 'react'
import FullCalendar from '@fullcalendar/react'

export type CalendarContextProps = {
  calendarRef: React.RefObject<FullCalendar>
}

export const CalendarContext = createContext({} as CalendarContextProps)

export const useCalendarContext = () => {
  const context = useContext(CalendarContext)

  if (!context)
    throw new Error('useCalendarContext must be use inside CalendarProvider')

  return context
}
