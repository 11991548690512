import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
// import { Provider as ReduxProvider } from 'react-redux'
// import { store } from './store'
import App from 'src/App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        {/* <ReduxProvider store={store}> */}
        <App />
        {/* </ReduxProvider> */}
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
)
