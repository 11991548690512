import { useState } from 'react'
import Box from '@mui/material/Box'
import { Collapse } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAutocomplete } from '@mui/base/useAutocomplete'
import CloseIcon from '@mui/icons-material/Close'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import ChevronDownIcon from './SvgIcons/ChevronDownIcon'

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`

const InputWrapper = styled('div')`
  min-height: 32px;
  line-height: 0;
  border: 2px solid #f4f5f7;
  background-color: #f4f5f7;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  transition:
    background-color 200ms ease-in-out 0s,
    border-color 200ms ease-in-out 0s;
  box-sizing: border-box;
  align-items: center;

  &:hover {
    border-color: #ebecf0;
    background-color: #ebecf0;
    cursor: pointer;
  }

  &.focused {
    border-color: #4c9aff;
    background-color: #fff;
  }

  & input {
    background-color: transparent;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 12px;
    box-sizing: border-box;
    padding: 6px 8px;
    width: 100%;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`

const Tag = styled(
  ({ label, data, onDelete, onDeleteItem, status, ...props }) => (
    <div {...props}>
      <span>{label}</span>
      <CloseIcon
        onClick={() => {
          console.log('status', status, data)
          if (status === SUCCESS || status === IDLE) {
            onDelete()
            onDeleteItem(data)
          }
        }}
      />
    </div>
  )
)`
  display: flex;
  max-width: 555px;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #dfe1e6;
  border-radius: 2px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    padding: 2px 2px 2px 6px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 14px;
    cursor: pointer;
    padding: 5px;
    fill: #000;
    &:hover {
      background-color: #ffbdad;
      fill: #000;
    }
  }
`

const Listbox = styled('ul')(({ theme }) => ({
  position: 'relative',
  margin: '8px 0',
  padding: '8px 0',
  listStyle: 'none',
  backgroundColor: '#fff',
  overflow: 'auto',
  maxHeight: '250px',
  borderRadius: '4px',
  boxShadow: theme.customShadows.modalShadow,
  zIndex: 1,

  ' & li': {
    padding: '6px 12px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginRight: '5px',
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
      fontSize: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& #checkbox': {
      display: 'flex',
      width: '24px',
      height: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'calc(2px * 12 / 7) solid #DFE1E6',
      borderRadius: '3px',
      outline: 'none',
      transform: 'scale(calc(0.583333))',
      transition:
        'transform 0.2s, border-color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s',
      backgroundColor: '#FAFBFC',
    },
  },
  [`& li.${autocompleteClasses.focused}`]: {
    backgroundColor: '#f4f5f7',
    cursor: 'pointer',
    // boxShadow: 'inset 2px 0px 0px #0052cc',
    '& #checkbox': {
      backgroundColor: '#ebecf0',
    },
    '&:active': {
      '& #checkbox': {
        backgroundColor: '#DEEBFF',
        borderColor: '#4C9AFF',
      },
    },
  },
  '& li[aria-selected="true"]': {
    '& #checkbox': {
      backgroundColor: '#0052cc',
      border: 'calc(2px * 12 / 7) solid #0052cc',
      '&:after': {
        content: '""',
        transition:
          'border-color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s',
        display: 'block',
        boxSizing: 'border-box',
        position: 'absolute',
        left: '1px',
        top: '-5px',
        width: '9px',
        height: '14px',
        borderWidth: '0 4px 4px 0',
        borderStyle: 'solid',
        transformOrigin: 'bottom left',
        transform: 'rotate(45deg)',
        borderColor: '#fff',
      },
    },
    '&:hover': {
      '& #checkbox': {
        backgroundColor: '#0065FF',
        borderColor: '#0065FF',
      },
    },
    '&:active': {
      '& #checkbox': {
        backgroundColor: '#DEEBFF',
        borderColor: '#0052CC',
        '&::after': {
          backgroundColor: '#DEEBFF',
          borderColor: '#0052CC',
        },
      },
    },
  },
  '& li:active': {
    backgroundColor: '#ebecf0',
    '& span': {
      color: '#000',
    },
  },
}))

export default function CustomSelect({
  id,
  name,
  placeholder,
  dataArr,
  handleChange,
  handleCloseSelect,
  statusFetch,
  handleDelete,
}) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id,
    disableCloseOnSelect: true,
    multiple: true,
    options: dataArr.data,
    value: dataArr.select,
    onChange: handleChange,
    onClose: () => {
      setOpen(false)
    },
    getOptionLabel: (option) => option.name,
    onOpen: () => {
      setOpen(true)
    },
  })
  const [open, setOpen] = useState(false)
  const { onMouseDown } = getInputProps()

  const handleClickDropMenu = () => {
    if (dataArr) {
      onMouseDown()
    }
  }

  return (
    <>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap' }}>
            {value.map((option, index) => (
              <Tag
                key={option.id}
                data={option.id}
                label={option.name}
                onDeleteItem={handleDelete}
                // status={statusFetch}
                {...getTagProps({ index })}
              />
            ))}

            <input
              {...getInputProps()}
              onMouseDown={handleClickDropMenu}
              placeholder={dataArr.select.length === 0 ? placeholder : ''}
            />
          </div>
          <Box onClick={handleClickDropMenu}>
            <ChevronDownIcon />
          </Box>
        </InputWrapper>
      </div>
      <Collapse in={open}>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => {
              const obj = getOptionProps({ option, index })
              return (
                <li key={index} {...obj}>
                  {/* <CheckboxIcon /> */}
                  <span id="checkbox"></span>
                  <span>{option.name}</span>
                </li>
              )
            })}
          </Listbox>
        ) : null}
      </Collapse>
    </>
  )
}
