import { useMemo } from 'react'
import useSWR, { mutate } from 'swr'

import { fetcherPost, endpoints } from 'src/utils/axios'

import { ICalendarEvent } from 'src/types/calendar'

// ----------------------------------------------------------------------

const URL = endpoints.issue.path
const postData = {
  expand: ['names'],
  fields: ['summary', 'status', 'assignee'],
  fieldsByKeys: true,
  jql: 'issue.property[EmbeddedDatesTLC].StartDate is not EMPTY AND project = CUM',
  maxResults: 15,
  startAt: 0,
}

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

export function useGetIssues() {
  const { data, isLoading, error, isValidating } = useSWR(
    [URL, postData],
    fetcherPost,
    options,
  )

  const memoizedValue = useMemo(() => {
    const issues = data?.issues.map((issue: ICalendarEvent) => ({
      ...issue,
      textColor: issue.color,
    }))

    return {
      issues: (issues as ICalendarEvent[]) || [],
      issuesLoading: isLoading,
      issuesError: error,
      issuesValidating: isValidating,
      issuesEmpty: !isLoading && !data?.issues.length,
    }
  }, [data?.issues, error, isLoading, isValidating])

  return memoizedValue
}

// ----------------------------------------------------------------------

export async function createIssue(eventData: ICalendarEvent) {
  /** Work on server */
  // const data = { eventData };
  // await axios.post(URL, data);

  /** Work in local */

  mutate(
    [URL, postData],
    (currentData: any) => {
      const issues: ICalendarEvent[] = [...currentData.issues, eventData]

      return {
        ...currentData,
        issues,
      }
    },
    false,
  )
}

// ----------------------------------------------------------------------

export async function updateIssue(eventData: Partial<ICalendarEvent>) {
  /** Work on server */
  // const data = { eventData };
  // await axios.put(endpoints.calendar, data);

  /** Work in local */
  mutate(
    [URL, postData],
    (currentData: any) => {
      console.log('currentData', currentData)
      const issues: ICalendarEvent[] = currentData.issues.map(
        (issue: ICalendarEvent) =>
          issue.id === eventData.id ? { ...issue, ...eventData } : issue,
      )

      return {
        ...currentData,
        issues,
      }
    },
    false,
  )
}

// ----------------------------------------------------------------------

export async function deleteIssue(eventId: string) {
  /** Work on server */
  // const data = { eventId };
  // await axios.patch(endpoints.calendar, data);

  /** Work in local */
  mutate(
    [URL, postData],
    (currentData: any) => {
      console.log('deleteIssues', currentData)
      const issues: ICalendarEvent[] = currentData.issues.filter(
        (issue: ICalendarEvent) => issue.id !== eventId,
      )

      return {
        ...currentData,
        issues,
      }
    },
    false,
  )
}
