import { RED, YELLOW, GREEN, BLUE, MAGENTA, DARKNEUTRAL } from '../colors'

const defaultDark = {
  lighter: DARKNEUTRAL['A400'],
  ligth: DARKNEUTRAL['A100'],
  main: DARKNEUTRAL['A200'],
  dark: DARKNEUTRAL['A300'], // hover button
  contrastText: DARKNEUTRAL[900],
}

const primaryDark = {
  lighter: BLUE[200],
  light: BLUE[500],
  main: BLUE[400],
  dark: BLUE[300], // hover button
  darker: BLUE[900],
  contrastText: DARKNEUTRAL[100],
}

const secondaryDark = {
  lighter: MAGENTA[200],
  light: MAGENTA[500],
  main: MAGENTA[400],
  dark: MAGENTA[300], // hover button
  darker: MAGENTA[600],
  contrastText: DARKNEUTRAL[100],
}

const infoDark = {
  lighter: BLUE[200],
  light: BLUE[500],
  main: BLUE[400],
  dark: BLUE[300], // hover button
  darker: BLUE[600],
  contrastText: DARKNEUTRAL[100],
}

const successDark = {
  lighter: GREEN[200],
  light: GREEN[500],
  main: GREEN[400],
  dark: GREEN[300], // hover button
  darker: GREEN[600],
  contrastText: DARKNEUTRAL[100],
}

const warningDark = {
  lighter: YELLOW[200],
  light: YELLOW[500],
  main: YELLOW[400],
  dark: YELLOW[300], // hover button
  darker: YELLOW[500],
  contrastText: DARKNEUTRAL[100],
}

const errorDark = {
  lighter: RED[100],
  light: RED[200],
  main: RED[400],
  dark: RED[300], // hover button
  darker: RED[900],
  contrastText: DARKNEUTRAL[100],
}

const actionDark = {
  hover: DARKNEUTRAL[250],
  active: DARKNEUTRAL['A400'],
  selected: BLUE[400],
  disabled: DARKNEUTRAL['A400'],
  disabledBackground: DARKNEUTRAL['A100'],
  // focus: alpha(grey[500], 0.24),
  // hoverOpacity: 0.08,
  disabledOpacity: 0.48,
}

export const darkMode = {
  default: defaultDark,
  primary: primaryDark,
  secondary: secondaryDark,
  info: infoDark,
  success: successDark,
  warning: warningDark,
  error: errorDark,
  action: actionDark,
}
