import { forwardRef, useEffect, useState, useRef } from 'react'
import Card from '@mui/material/Card'
import {
  CardContent,
  Box,
  Fade,
  InputBase,
  FormControlLabel,
  Typography,
  LinearProgress,
} from '@mui/material'
import { TacCheckbox } from '@goard/tac-ui-kit'
import CustomSelect from 'src/components/CustomSelect'
import { Droppable } from '@hello-pangea/dnd'
import { IssueItem } from './issue-item'
import { ThirdPartyDraggable } from '@fullcalendar/interaction'
import { useGetIssues } from 'src/api/issue'
import { useGetCalendars } from 'src/api/calendar'

const arrItems = [
  { id: 1, name: 'Option1', title: 'COMPANY-1' },
  { id: 2, name: 'Option2', title: 'COMPANY-1' },
  { id: 3, name: 'Option3', title: 'COMPANY-1' },
  { id: 4, name: 'Option4', title: 'COMPANY-1' },
  { id: 5, name: 'Option5', title: 'COMPANY-1' },
  { id: 6, name: 'Option6', title: 'COMPANY-1' },
  { id: 7, name: 'Option7', title: 'COMPANY-1' },
  { id: 8, name: 'Option8', title: 'COMPANY-1' },
]

const UnplannedCard = forwardRef<HTMLDivElement, any>(
  ({ handleClickOpen, ...others }, ref) => {
    const [data, setData] = useState({ select: [], data: arrItems })
    const containerEl = useRef(null)

    const postData = {
      expand: ['names'],
      fields: ['summary', 'status', 'assignee'],
      fieldsByKeys: true,
      jql: 'issue.property[EmbeddedDatesTLC].StartDate is not EMPTY AND project = CUM',
      maxResults: 15,
      startAt: 0,
    }
    const { issues, issuesLoading } = useGetIssues(postData)
    // const { calendars } = useGetCalendars()

    const handleChange = (event, newValue) => {
      setData({ ...data, select: newValue })
    }
    const [open, setOpen] = useState(true)

    const handleClick = () => {
      setOpen(!open)
    }

    const handleCloseSelect = () => {
      if (state.select.length !== 0) {
        // dispatch(reportStateIsStart());
        // dispatch(getReportByEpics(state.select));
      }
    }

    const handleDelete = (data) => {
      console.log('delete', data)
      const filterArr = state.select.filter((el) => el.id !== data)(
        setData({ ...data, select: filterArr }),
      )
    }

    useEffect(() => {
      const draggable = new ThirdPartyDraggable(containerEl.current, {
        itemSelector: '.external-event',
        eventData: function (eventEl) {
          return {
            id: eventEl.getAttribute('data-id'),
            title: eventEl.getAttribute('data-title'),
            textColor: '#94C748',
            start: new Date(),
          }
        },
      })
      return () => {
        draggable.destroy()
      }
    }, [])

    return (
      <Fade in={true}>
        <Card {...others} ref={ref}>
          <CardContent
            sx={{
              minHeight: '170px',
              maxHeight: `calc(100vh - 182px)`,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: '3px',
                backgroundColor: '#EBECF0',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#6B778C',
                borderRadius: '3px',
                height: '16px',
              },
              padding: '14px 14px 14px 14px',
              '&:last-child': {
                paddingBottom: '14px',
              },
              transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            <CustomSelect
              id="multiple-option"
              name="option"
              placeholder="Assignee: All"
              dataArr={data}
              handleChange={handleChange}
              handleCloseSelect={handleCloseSelect}
              handleDelete={handleDelete}
            />
            <InputBase
              sx={{
                padding: '6px 8px',
                fontSize: '12px',
                marginTop: '8px',
                backgroundColor: '#FAFBFC',
                border: '2px solid #DFE1E6',
                borderRadius: '3px',
                minHeight: '32px',
                '&.Mui-focused': {
                  border: '2px solid #4c9aff',
                },
                '& .MuiInputBase-input': {
                  padding: 0,
                },
              }}
              // notched={false}
              placeholder="Contains text"
            />
            <Box
              sx={{
                marginTop: '8px',
                marginLeft: '5px',
                marginBottom: '8px',
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: '-5px', marginRight: 0 }}
                control={<TacCheckbox color="secondary" />}
                label={
                  <Typography
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    Show resolved issues
                  </Typography>
                }
              />
            </Box>
            <div ref={containerEl}>
              <Droppable droppableId="unplannedIssues">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {issues.map((el, index) => (
                      <IssueItem item={el} index={index} key={el.id} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </CardContent>
          {issuesLoading && (
            <LinearProgress
              color="inherit"
              sx={{
                height: 2,
                width: 1,
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
          )}
        </Card>
      </Fade>
    )
  },
)

export default UnplannedCard
