import merge from 'lodash/merge'
// date fns
import {
  fr as frFRAdapter,
  enUS as enUSAdapter,
  de as deDEAdapter,
  es as esESAdapter,
  pt as ptPTAdapter,
  pl as plPLAdapter,
} from 'date-fns/locale'
// date pickers (MUI)
import {
  enUS as enUSDate,
  frFR as frFRDate,
  deDE as deDEDate,
  esES as esESDate,
  ptBR as ptBRDate,
  plPL as plPLDate,
} from '@mui/x-date-pickers/locales'
// core (MUI)
import {
  enUS as enUSCore,
  frFR as frFRCore,
  deDE as deDECore,
  esES as esESCore,
  ptPT as ptPTCore,
  plPL as plPLCore,
} from '@mui/material/locale'
// data grid (MUI)
import {
  enUS as enUSDataGrid,
  frFR as frFRDataGrid,
  deDE as deDEDataGrid,
  esES as esESDataGrid,
  ptPT as ptPTDataGrid,
  plPL as plPLDataGrid,
} from '@mui/x-data-grid'

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDataGrid, enUSCore, enUSDate),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
    numberFormat: {
      code: 'en-US',
      currency: 'USD',
    },
  },
  {
    label: 'German',
    value: 'de',
    systemValue: merge(deDEDataGrid, deDECore, deDEDate),
    adapterLocale: deDEAdapter,
    icon: 'flagpack:de',
    numberFormat: {
      code: 'de-De',
      currency: 'EUR',
    },
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: merge(frFRDataGrid, frFRCore, frFRDate),
    adapterLocale: frFRAdapter,
    icon: 'flagpack:fr',
    numberFormat: {
      code: 'fr-Fr',
      currency: 'EUR',
    },
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: merge(esESDataGrid, esESCore, esESDate),
    adapterLocale: esESAdapter,
    icon: 'flagpack:es',
    numberFormat: {
      code: 'es-ES',
      currency: 'EUR',
    },
  },
  {
    label: 'Portugese',
    value: 'pt',
    systemValue: merge(ptPTDataGrid, ptPTCore, ptBRDate),
    adapterLocale: ptPTAdapter,
    icon: 'flagpack:pt',
    numberFormat: {
      code: 'pt',
      currency: 'EUR',
    },
  },
  {
    label: 'Polish',
    value: 'pl',
    systemValue: merge(plPLDataGrid, plPLCore, plPLDate),
    adapterLocale: plPLAdapter,
    icon: 'flagpack:pl',
    numberFormat: {
      code: 'pl',
      currency: 'PLN',
    },
  },
]

export const defaultLang = allLangs[0] // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
