{
  "month": "month",
  "week": "week",
  "day": "day",
  "today": "today",
  "main": "main",
  "unplanned issues": "unplanned issues",
  "create": "create",
  "calendars": "calendars",
  "issues": "issues",
  "menu": "menu",
  "guide": "guide",
  "share": "share",
  "news": "news",
  "settings": "settings",
  "language": "language",
  "internal calendars": "internal calendars",
  "external calendars": "external calendars",
  "demo": {
    "title": "English"
  }
}
