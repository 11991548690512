/* eslint-disable perfectionist/sort-imports */
import 'src/global.css'

// i18n
import 'src/locales/i18n'

import Router from 'src/routes'
import { useScrollToTop } from 'src/hooks/use-scroll-to-top'
import ThemeProvider from 'src/theme'
import { LocalizationProvider } from './locales'
import ProgressBar from 'src/components/progress-bar'
import { MotionLazy } from 'src/components/animate/motion-lazy'
import { SettingsDrawer, SettingsProvider } from 'src/components/settings'
import { CalendarProvider } from 'src/sections/calendar/context'
import { DragDropContext } from '@hello-pangea/dnd'
import { useGetCalendars } from './api/calendar'

function App() {
  const charAt = `

  ░░░░░░░░░░
      ▒▒
      ▒▒
      ▓▓
      ██

  `

  console.info(`%c${charAt}`, 'color: #5BE49B')

  useScrollToTop()

  function onDragEnd(result) {
    console.log('result', result)
    return
  }

  const { calendars } = useGetCalendars()

  return (
    <>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <LocalizationProvider>
            <MotionLazy>
              <CalendarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Router />
                </DragDropContext>
              </CalendarProvider>
            </MotionLazy>
          </LocalizationProvider>
        </ThemeProvider>
      </SettingsProvider>
    </>
  )
}

export default App
