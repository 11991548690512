// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 0,
  H_DESKTOP: 0,
  H_DESKTOP_OFFSET: 0,
}

export const NAV = {
  W_VERTICAL: 300,
  W_MINI: 80,
}
