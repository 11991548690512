import Box, { BoxProps } from '@mui/material/Box'

import { useResponsive } from 'src/hooks/use-responsive'

import { useSettingsContext } from 'src/components/settings'

import { NAV, HEADER } from '../config-layout'

// ----------------------------------------------------------------------

const SPACING = 8

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext()

  const mdUp = useResponsive('up', 'md')

  const isNavMini = settings.themeLayout === 'mini'

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(mdUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  )
}
