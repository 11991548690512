import Box from '@mui/material/Box'

import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'

import { useSettingsContext } from 'src/components/settings'

import { NAV } from '../config-layout'

import Main from './main'
import NavMini from './nav-mini'
import NavVertical from './nav-vertical'
import { useNavData } from './config-navigation'
import { useGetCalendars } from 'src/api/calendar'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
  isMini?: boolean
}

function WrapperBox({ children, isMini }: Props) {
  return (
    <Box
      sx={{
        position: 'relative',
        flexShrink: { md: 0 },
        width: isMini ? { md: NAV.W_MINI } : { md: NAV.W_VERTICAL },
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {children}
    </Box>
  )
}

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext()

  const mdUp = useResponsive('up', 'md')

  const nav = useBoolean()

  const isMini = settings.themeLayout === 'mini'

  const navData = useNavData()

  const { calendars } = useGetCalendars()

  const renderNavMini = (
    <WrapperBox isMini={true}>
      <NavMini data={navData} />
    </WrapperBox>
  )

  const renderNavVertical = (
    <WrapperBox isMini={false}>
      <NavVertical
        data={navData}
        openNav={nav.value}
        onCloseNav={nav.onFalse}
      />
    </WrapperBox>
  )

  if (isMini) {
    return (
      <>
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Main>{children}</Main>
          {mdUp ? renderNavMini : renderNavVertical}
        </Box>
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Main>{children}</Main>
        {renderNavVertical}
      </Box>
    </>
  )
}
