import { useMemo, useRef } from 'react'
import { CalendarContext } from './calendar-context'

type CalendarProviderProps = {
  children: React.ReactNode
}

export function CalendarProvider({ children }: CalendarProviderProps) {
  const calendarRef = useRef(null)

  const memoizedValue = useMemo(
    () => ({
      calendarRef,
    }),
    [calendarRef]
  )

  return (
    <CalendarContext.Provider value={memoizedValue}>
      {children}
    </CalendarContext.Provider>
  )
}
