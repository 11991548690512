import { SvgIcon } from '@mui/material'

export default function ChevronDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect width="24" height="24" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29195 10.2929C8.10514 10.4818 8.00037 10.7368 8.00037 11.0024C8.00037 11.2681 8.10514 11.523 8.29195 11.7119L11.2309 14.6769C11.4489 14.8919 11.7309 14.9989 12.0099 14.9989C12.2889 14.9989 12.5659 14.8919 12.7789 14.6769L15.7089 11.7219C15.8955 11.5329 16.0001 11.278 16.0001 11.0124C16.0001 10.7469 15.8955 10.492 15.7089 10.3029C15.6171 10.2098 15.5077 10.1358 15.387 10.0853C15.2663 10.0347 15.1368 10.0087 15.0059 10.0087C14.8751 10.0087 14.7456 10.0347 14.6249 10.0853C14.5042 10.1358 14.3948 10.2098 14.3029 10.3029L12.0049 12.6199L9.69795 10.2929C9.60591 10.2001 9.4964 10.1264 9.37574 10.0762C9.25508 10.0259 9.12566 10 8.99495 10C8.86423 10 8.73481 10.0259 8.61415 10.0762C8.49349 10.1264 8.38398 10.2001 8.29195 10.2929Z"
        fill="#42526E"
      />
    </SvgIcon>
  )
}
